import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Container,
  Typography,
  Box,
  IconButton,
  TextField,
  Snackbar,
  Grid,
} from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { Instagram, Twitter } from "@mui/icons-material";
import { FaTwitch, FaSnapchatGhost, FaSoundcloud, FaTiktok } from "react-icons/fa";
import MuiAlert from "@mui/material/Alert";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";

// Animation for sliding text (marquee effect)
const slideAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

// AppBar styling
const AppBarStyled = styled(AppBar)(() => ({
  backgroundColor: "#000",
  borderBottom: "1px solid white",
  height: "70px",
  padding: "10px",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  position: "relative",
}));

const Logo = styled("img")({
  height: "60px",
  position: "absolute",
  left: "10px",
});

const MarqueeText = styled(Typography)(() => ({
  whiteSpace: "nowrap",
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: "white",
  animation: `${slideAnimation} 15s linear infinite`,
}));

const CenteredText = styled(Typography)(() => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: "white",
  textAlign: "center",
}));

const SectionContainer = styled(Container)(() => ({
  padding: "50px 0",
}));

// Adjust the footer to behave properly
const FooterContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 20px",
  backgroundColor: "#000",
  borderTop: "1px solid white",
  color: "#fff",
  width: "100%",
  marginTop: "auto",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    textAlign: "center",
  },
}));

const HeaderBox = styled(Box)(() => ({
  position: "relative",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  textAlign: "center",
  overflow: "hidden",
}));

const PhotoCollage = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridGap: "10px",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 1,

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  "@media (max-width: 600px)": {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(3, 1fr)",
  },
}));

const ContentContainer = styled(Container)(() => ({
  position: "relative",
  zIndex: 2, // Ensures the content is above the photos
}));

const EventImage = styled("img")(() => ({
  width: "100%",
  height: "auto",
  maxWidth: "500px",
  "@media (max-width: 600px)": {
    maxWidth: "100%", // Make the image take full width on mobile
  },
}));

const App = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    details: "",
  });
  const [formError, setFormError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSliding, setIsSliding] = useState(true); // Control if sliding or centered

  const theme = useTheme();

  // Effect to stop the sliding after 3 iterations
  useEffect(() => {
    if (isSliding) {
      const slideDuration = 15000; // 15 seconds for one complete slide
      const totalDuration = slideDuration * 3; // Stop after 3 iterations

      const timer = setTimeout(() => {
        setIsSliding(false);
      }, totalDuration);

      return () => clearTimeout(timer);
    }
  }, [isSliding]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.phone || !formData.details) {
      setFormError("All fields are required");
      setSnackbarMessage("Please fill in all fields");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setIsSubmitting(true);

    // Web3Forms submission
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        access_key: "96b44523-9f67-4131-bc15-8c33010db272",
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.details,
      }),
    });

    const result = await response.json();

    if (result.success) {
      setSnackbarMessage("Submission sent successfully");
      setSnackbarSeverity("success");
      setFormData({ name: "", email: "", phone: "", details: "" });
      setFormError("");
    } else {
      setSnackbarMessage("Submission failed. Please try again.");
      setSnackbarSeverity("error");
    }

    setOpenSnackbar(true);
    setIsSubmitting(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="min-h-screen" style={{ display: "flex", flexDirection: "column", backgroundColor: "#000" }}>
        {/* AppBar with sliding text */}
        <AppBarStyled position="static">
          {/* Logo */}
          <Logo src="/djczeblack.jpeg" alt="DJ Cze Logo" />

          {/* Sliding or Centered Text */}
          <Box sx={{ width: "100%", overflow: "hidden" }}>
            {isSliding ? (
              <MarqueeText>
                Official Website of DJ CZE &nbsp;&nbsp;&nbsp; Official Website of DJ CZE &nbsp;&nbsp;&nbsp; Official Website of DJ CZE
              </MarqueeText>
            ) : (
              <CenteredText>Official Website of DJ CZE</CenteredText>
            )}
          </Box>
        </AppBarStyled>

        {/* Hero Section with 3-photo collage */}
        <HeaderBox>
          <PhotoCollage>
            <img src="/pinktee.jpeg" alt="DJ Performance" />
            <img src="/microphone.jpeg" alt="DJ Crowd" />
            <img src="/birdman.jpeg" alt="DJ Stage" />
          </PhotoCollage>
        </HeaderBox>

        {/* About Section */}
        <SectionContainer>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6}>
              <img
                src="/cyabout.jpeg"
                className="max-w-sm rounded-lg shadow-2xl"
                alt="DJ Photo"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" gutterBottom>
                About DJ Cze
              </Typography>
              <Typography variant="body1" paragraph>
                With 2 years of professional experience and 8 years as an aficionado, CZE, a Montreal-based DJ, began his journey by regularly attending parties and reimagining how they could be improved. His passion for curating events led him to master the art of DJing. Versatile across genres, CZE specializes in Caribbean music such as dancehall and soca, while also being trusted to headline events featuring latin, house, hip hop and R&B music, amongst others.
                <br />
                <br />
                Within his first 2 years as a professional, CZE has impressed industry insiders, securing major bookings in both Montreal and Toronto’s Black communities. From music festivals and local carnivals to large-scale performances and private events, CZE’s talent continues to be in high demand.
              </Typography>
            </Grid>
          </Grid>
        </SectionContainer>

        {/* Booking Section */}
        <SectionContainer>
          <Typography variant="h4" gutterBottom>
            Contact / Book Me
          </Typography>
          <form className="form-control space-y-4" onSubmit={handleSubmit}>
            <TextField
              type="text"
              label="Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                style: { color: "#000", backgroundColor: "#fff", borderRadius: "5px" },
              }}
            />
            <TextField
              type="email"
              label="Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                style: { color: "#000", backgroundColor: "#fff", borderRadius: "5px" },
              }}
            />
            <TextField
              type="tel"
              label="Phone Number"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                style: { color: "#000", backgroundColor: "#fff", borderRadius: "5px" },
              }}
            />
            <TextField
              label="Event Details"
              value={formData.details}
              onChange={(e) => setFormData({ ...formData, details: e.target.value })}
              fullWidth
              required
              multiline
              rows={4}
              variant="outlined"
              InputProps={{
                style: { color: "#000", backgroundColor: "#fff", borderRadius: "5px" },
              }}
            />
            {formError && <Typography color="error">{formError}</Typography>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </SectionContainer>

        {/* Events Section */}
        <SectionContainer>
          <Typography variant="h4" gutterBottom>
            Bashment & Bacchanal
          </Typography>

          <div className="card card-compact bg-base-100 shadow-xl">
            <figure>
              <EventImage src="bday.jpg" alt="Event Image" />
            </figure>
            <div className="card-body" style={{ backgroundColor: "#000" }}>
              {/* Ensure the card body background is black */}
              <div className="card-actions justify-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.location.href =
                      "https://www.ticketgateway.com/event/view/basment---bacchanal--birthstone-edition---djcze-birthday"
                  }
                >
                  Purchase Tickets Now
                </Button>
              </div>
            </div>
          </div>
        </SectionContainer>

        {/* Upcoming Events Section */}
        <SectionContainer>
          <Typography variant="h4" gutterBottom>
            Upcoming Events
          </Typography>
          <Box>
            <img src="/november.jpg" alt="DJ Schedule" className="w-full rounded-lg shadow-2xl" />
          </Box>
        </SectionContainer>

        {/* Footer */}
        <FooterContainer>
          <Box>
            <Logo src="/djczeblack.jpeg" alt="DJ Logo" />
          </Box>
          <Box>
            <IconButton color="inherit" href="https://www.twitch.tv/DjCzeMTL">
              <FaTwitch />
            </IconButton>
            <IconButton color="inherit" href="https://www.snapchat.com/add/yungcze?share_id=OEY2NkQw&locale=en_CA">
              <FaSnapchatGhost />
            </IconButton>
            <IconButton color="inherit" href="https://www.soundcloud.com/djcze">
              <FaSoundcloud />
            </IconButton>
            <IconButton color="inherit" href="https://www.instagram.com/dj.cze">
              <Instagram />
            </IconButton>
            <IconButton color="inherit" href="https://www.tiktok.com/@dj.cze">
              <FaTiktok />
            </IconButton>
            <IconButton color="inherit" href="https://x.com/yungcze">
              <Twitter />
            </IconButton>
          </Box>
        </FooterContainer>

        {/* Snackbar for form submission */}
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
};

export default App;
